<template>
	<div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" v-loading='loading'>
			<el-card class="box-card" style="margin-bottom: 10px">
				<div slot="header" class="clearfix">签署保密协议</div>
				<el-form-item label="保密协议：" class="account-item" prop="ConfidentialityAgreementImgUrlList" required>
					<div class="remark">
						上传加盖公章的原件照片或扫描件，共5张，支持.jpg.jpeg.bmp.gif.png格式照片，大小不超过10M。
					</div>
					<el-button type="text" style="display:block">
						<a :href="imgUrl + '/image/保密协议.pdf?response-content-type=application/octet-stream'" download="保密协议">下载保密协议模板</a>
					</el-button>
					<div style="margin-right: 20px;display:inline-block;text-align:center" v-for="(item,index) in ruleForm.ConfidentialityAgreementImgUrlList"
					 :key="index">
						<img :src="imgUrl+item" alt="" style="width: 145px;height: 145px;">
						<br>
						<el-button v-if="canEdit" type='text' style="color:#F56C6C" @click="handleDelete(index)">删除</el-button>
					</div>
					<el-upload ref="uploader" :disabled='!canEdit || thedisabled' v-show="ruleForm.ConfidentialityAgreementImgUrlList.length<5" :action="imgApi"
					 list-type="picture-card" style="display:inline-block;vertical-align: top;" :on-success="handleSuccessPoster1" :on-progress = "onprogress1"
					 :on-error = "onerror1"
					 :before-upload="beforeUploadPoster"  accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp" :on-exceed = "handleExceed" :show-file-list="false" multiple >
						<div>
							<span style="color:#409EFF">上传保密协议</span>
						</div>
					</el-upload>
				</el-form-item>
			</el-card>

			<el-card class="box-card" style="margin-bottom: 10px">
				<div slot="header" class="clearfix">基本信息</div>
				<el-form-item label="联系人：" prop="ContactName">
					<el-input :disabled='!canEdit' v-model="ruleForm.ContactName" placeholder="请输入联系人姓名" maxlength="25" style='width:500px;'></el-input>
				</el-form-item>
				<el-form-item label="联系手机号：" prop="ContactPhone">
					<el-input :disabled='!canEdit' v-model="ruleForm.ContactPhone" placeholder="请输入联系手机号" maxlength="11" style='width:500px;'></el-input>
				</el-form-item>
				<el-form-item label="电子邮箱：" prop="Email">
					<el-input :disabled='!canEdit' v-model="ruleForm.Email" placeholder="请输入电子邮箱" style='width:500px;'></el-input>
				</el-form-item>
				<el-form-item label="营业执照照片：" prop="BusinessLicenseImgUrl" class="account-item">
					<span class="remark">
						支持.jpg.jpeg.bmp.gif.png格式照片，大小不超过10M。
					</span>
					<el-upload :disabled='!canEdit' class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleSuccessPoster2"
					 :before-upload="beforeUploadPoster" v-loading="imgLoading1" element-loading-text="图片识别中"
					 element-loading-custom-class='img-scan-loading' accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp"
					 list-type="picture-card">
						<!-- imgLoading1 -->
						<img v-if="ruleForm.BusinessLicenseImgUrl" :src="imgUrl + ruleForm.BusinessLicenseImgUrl" class="upload-avatar" />
						<span v-else style="color:#409EFF">上传营业执照</span>
					</el-upload>
				</el-form-item>

				<el-form-item label="主体名称：" prop="CompanyName">
					<el-input :disabled='!canEdit' v-model="ruleForm.CompanyName" placeholder="请输入营业执照上的名称" maxlength="50" style='width:500px;'></el-input>
				</el-form-item>

				<el-form-item label="商户简称：" prop="CompanyNameShort">
					<el-input :disabled='!canEdit' v-model="ruleForm.CompanyNameShort" placeholder="请输入商户简称，建议2-10个字" maxlength="10"
					 style='width:500px;'></el-input>
					<div style="color:#909399;font-size:14px">将在支付完成页向买家展示</div>
				</el-form-item>

				<el-form-item label="营业执照注册号：" prop="BusinessLicenseRegistrationNumber">
					<el-input :disabled='!canEdit' v-model="ruleForm.BusinessLicenseRegistrationNumber" placeholder="请输入营业执照注册号" style='width:500px;'></el-input>
				</el-form-item>

				<el-form-item label="营业执照地址：" required>
					<el-form-item prop="BusinessLicenseAddressArr">
						<el-cascader :disabled='!canEdit' style='width:500px;' v-model="ruleForm.BusinessLicenseAddressArr" placeholder="请选择省/市/区"
						 separator="-" :props="arrProps" :options="arrDataList" @change="handleAddressChange1"></el-cascader>
					</el-form-item>
					<el-form-item prop="BusinessLicenseAddress">
						<el-input :disabled='!canEdit' style="margin-top:20px;width:500px;" placeholder="详细地址" type="textarea" :rows="4"
						 v-model="ruleForm.BusinessLicenseAddress" maxlength="100"></el-input>
					</el-form-item>
				</el-form-item>

				<el-form-item label="营业地址：" required>
					<el-form-item prop="BusinessAddressArr">
						<el-cascader :disabled='!canEdit' style='width:500px;' v-model="ruleForm.BusinessAddressArr" placeholder="请选择省/市/区"
						 separator="-" :props="arrProps" :options="arrDataList" @change="handleAddressChange2"></el-cascader>
					</el-form-item>
					<el-form-item prop="BusinessAddress">
						<el-input :disabled='!canEdit' style="margin-top:20px;width:500px;" placeholder="详细地址" type="textarea" :rows="4"
						 v-model="ruleForm.BusinessAddress" maxlength="100"></el-input>
					</el-form-item>
				</el-form-item>
			</el-card>

			<el-card class="box-card" style="margin-bottom: 10px">
				<div slot="header" class="clearfix">法人信息</div>

				<el-form-item label="法人身份证照片：" required style="margin-top:10px;" class="account-item">
					<div class="remark">
						支持.jpg.jpeg.bmp.gif.png格式照片，大小不超过10M。
					</div>
					<el-form-item prop="LegalPersonHoldIdCardPic" style="display: inline-block;margin-right:10px;text-align:center;vertical-align:top;">
						<el-upload :disabled='!canEdit' class="avatar-uploader" :action="imgApi" :show-file-list="false" list-type="picture-card"
						 :on-success="handleSuccessPoster3" :before-upload="beforeUploadPoster" v-loading="imgLoading2"
						 element-loading-text="图片识别中" element-loading-custom-class='img-scan-loading' accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp">
							<img v-if="ruleForm.LegalPersonHoldIdCardPic" :src="imgUrl + ruleForm.LegalPersonHoldIdCardPic" class="upload-avatar" />
							<span v-else style="color:#409EFF">上传正面照</span>
						</el-upload>
					</el-form-item>
					<el-form-item prop="LegalPersonBackIdCardPic" style="display: inline-block;text-align:center;vertical-align:top;">
						<el-upload :disabled='!canEdit' class="avatar-uploader" :action="imgApi" :show-file-list="false" list-type="picture-card"
						 :on-success="handleSuccessPoster4" :before-upload="beforeUploadPoster" v-loading="imgLoading3"
						 element-loading-text="图片识别中" element-loading-custom-class='img-scan-loading' accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp">
							<img v-if="ruleForm.LegalPersonBackIdCardPic" :src="imgUrl + ruleForm.LegalPersonBackIdCardPic" class="upload-avatar" />
							<span v-else style="color:#409EFF">上传反面照</span>
						</el-upload>
					</el-form-item>
				</el-form-item>

				<el-form-item label="法人名字：" prop="LegalPersonName">
					<el-input :disabled='!canEdit' v-model="ruleForm.LegalPersonName" placeholder="请输入法人名字" maxlength="25" style='width:500px;'></el-input>
				</el-form-item>

				<el-form-item label="法人身份证号：" prop="LegalPersonIdCardNo">
					<el-input :disabled='!canEdit' v-model="ruleForm.LegalPersonIdCardNo" placeholder="请输入法人身份证号" maxlength="18" style='width:500px;'></el-input>
				</el-form-item>

				<el-form-item label="法人身份证有效期：" required>
					<div v-show="!ruleForm.longtime">
					<el-form-item :prop="ruleForm.longtime?'nocheck':'LegalPersonIdCardStartTime'" :rules="ruleForm.longtime?[{required:false}]:rules.LegalPersonIdCardStartTime" style="display: inline-block;">
						<el-input :disabled='!canEdit || ruleForm.longtime' v-model="ruleForm.LegalPersonIdCardStartTime" placeholder="请输入开始日期"></el-input>
					</el-form-item>
					<span style="margin:0 10px;">至</span>
					<el-form-item :prop="ruleForm.longtime?'nocheck':'LegalPersonIdCardEndTime'" :rules="ruleForm.longtime?[{required:false}]:rules.LegalPersonIdCardEndTime" style="display: inline-block;">
						<el-input :disabled='!canEdit || ruleForm.longtime' v-model="ruleForm.LegalPersonIdCardEndTime" placeholder="请输入结束日期"></el-input>
					</el-form-item>

					<el-checkbox  :disabled='!canEdit' v-model="ruleForm.longtime" style="margin:0px 30px">长期有效</el-checkbox>
						</div>
					<div v-show="ruleForm.longtime">
					<el-form-item :prop="ruleForm.longtime?'LegalPersonIdCardStartTime':'nocheck'" :rules="ruleForm.longtime?rules.LegalPersonIdCardStartTime:[{required:false}]" style="display: inline-block;">
						<el-input :disabled='!canEdit || !ruleForm.longtime' v-model="ruleForm.LegalPersonIdCardStartTime" placeholder="请输入开始日期"></el-input>
					</el-form-item>
					<span style="margin:0 10px;">~</span>
					<el-form-item  style="display: inline-block;">
						<el-checkbox  :disabled='!canEdit' v-model="ruleForm.longtime">长期有效</el-checkbox>
					</el-form-item>
					</div>

				</el-form-item>

			</el-card>

			<el-card class="box-card" style="margin-bottom: 10px">
				<div slot="header" class="clearfix">结算银行账号</div>

				<el-form-item label="开户银行：" prop="BankId" style="margin-top:10px;" class="account-item">
					<el-select :disabled='!canEdit' filterable v-model="ruleForm.BankId" style="width: 500px;" placeholder="请输入开户银行"
					 clearable>
						<el-option v-for="item in bankFilterList" :key="item.Id" :label="item.BankName" :value="item.Id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="开户支行：" prop="BankAccountOpeningBranch" style="margin-top:10px;" class="account-item">
					<el-input :disabled='!canEdit' v-model="ruleForm.BankAccountOpeningBranch" maxlength="50" placeholder="请输入开户支行："
					 style='width:500px;'></el-input>
				</el-form-item>

				<el-form-item label="银行开户名称：" prop="BankCardHolder" style="margin-top:10px;" class="account-item">
					<el-input :disabled='!canEdit' v-model="ruleForm.BankCardHolder" maxlength="50" placeholder="请输入银行开户姓名：" style='width:500px;'></el-input>
				</el-form-item>

				<el-form-item label="银行卡号：" prop="BankCardNo" style="margin-top:10px;" class="account-item">
					<el-input :disabled='!canEdit' v-model="ruleForm.BankCardNo" placeholder="请输入银行卡号" style='width:500px;'></el-input>
				</el-form-item>

				<el-form-item label="银行照片：" style="margin-top:10px;" class="account-item" prop="BankCardImgUrl">
					<span class="remark">
						支持.jpg.jpeg.bmp.gif.png格式照片，大小不超过10M。
					</span>
					<el-upload :disabled='!canEdit' class="avatar-uploader" :action="imgApi" :show-file-list="false" list-type="picture-card"
					 :on-success="handleSuccessPoster5" :before-upload="beforeUploadPoster" accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp">
						<img v-if="ruleForm.BankCardImgUrl" :src="imgUrl + ruleForm.BankCardImgUrl" class="upload-avatar" />
						<span v-else style="color:#409EFF">上传银行卡照片</span>
					</el-upload>
				</el-form-item>

				<el-form-item label="开户行许可证：" style="margin-top:10px;" class="account-item" prop="OpeningBranchLicenseImgUrl">
					<span class="remark">
						支持.jpg.jpeg.bmp.gif.png格式照片，大小不超过10M。
					</span>
					<el-upload :disabled='!canEdit' class="avatar-uploader" :action="imgApi" :show-file-list="false" list-type="picture-card"
					 :on-success="handleSuccessPoster6" :before-upload="beforeUploadPoster" accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp">
						<img v-if="ruleForm.OpeningBranchLicenseImgUrl" :src="imgUrl + ruleForm.OpeningBranchLicenseImgUrl" class="upload-avatar" />
						<span v-else style="color:#409EFF">上传开户行许可证</span>
					</el-upload>
				</el-form-item>

			</el-card>
			
			<el-card class="box-card" style="margin-bottom: 100px">
				<div slot="header" class="clearfix">门店信息(选填)</div>
				<el-form-item label="门店门头照：" style="margin-top:10px;" class="account-item" prop="ShopHeadImgUrl">
					<span class="remark" v-if="canEdit">
						支持.jpg.jpeg.bmp.gif.png格式照片，大小不超过10M。
					</span>
					<el-upload v-if="canEdit" :disabled='!canEdit' class="avatar-uploader" :action="imgApi" :show-file-list="false" list-type="picture-card"
					 :on-success="handleSuccessShopHead" :before-upload="beforeUploadShopHead" accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp">
						<img v-if="ruleForm.ShopHeadImgUrl" :src="imgUrl + ruleForm.ShopHeadImgUrl" class="upload-avatar" />
						<span v-else style="color:#409EFF">上传门头照</span>
					</el-upload>
				</el-form-item>
				
				<el-form-item label="门店内景照：" style="margin-top:10px;" class="account-item" prop="ShopHeadImgUrl">
					<span class="remark" v-if="canEdit">
						支持.jpg.jpeg.bmp.gif.png格式照片，大小不超过10M。
					</span>
					<el-upload v-if="canEdit" :disabled='!canEdit' class="avatar-uploader" :action="imgApi" :show-file-list="false" list-type="picture-card"
					 :on-success="handleSuccessShopInterior" :before-upload="beforeUploadShopInterior" accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp">
						<img v-if="ruleForm.ShopInteriorImgUrl" :src="imgUrl + ruleForm.ShopInteriorImgUrl" class="upload-avatar" />
						<span v-else style="color:#409EFF">上传内景照</span>
					</el-upload>
				</el-form-item>
			</el-card>
		</el-form>
		<div class="footer" v-loading='loading'>
			<el-button style="width:240px;" @click="routerBack">{{canEdit? '取消' : '返回'}}</el-button>
			<el-button style="width:240px;" type="primary" @click="saveForm('ruleForm')" v-if="canEdit">确认提交</el-button>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import config from '@/config/index';
	import {
		bankFilterList,
		freePayApply,
		// freePayApplyInfo,
		freePayApplyInit,
		recognitionIdCard,
		recognitionBusinessLicense
	} from '@/api/goods';

	export default {
		data() {
			var nocheck = (rule,value,callback)=>{
				callback()
			}
			var checkContactPhone = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入联系人手机号'));
				} else if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)) {
					return callback(new Error('手机号格式错误'));
				} else {
					return callback();
				}
			}
			var checkImgUrlList = (rule, value, callback) => {
				if (this.ruleForm.ConfidentialityAgreementImgUrlList.length < 5) {
					return callback(new Error('请上传保密协议照片，共5张照片'));
				} else {
					return callback();
				}
			}
			var checkEmail = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入电子邮箱'));
				} else {
					return callback();
				}
			}
			return {
				thedisabled:false,//在上一张图片上传完成之前禁止上传
				imgApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				loading: false,
				ApplyState: null,
				canEdit: null,
				ruleForm: {
					ConfidentialityAgreementImgUrlList: [],

					ContactName: '',
					ContactPhone: '',
					Email: '',
					BusinessLicenseImgUrl: '',
					CompanyName: '',
					CompanyNameShort: '',
					EnterpriseId: '',
					BusinessLicenseRegistrationNumber: '',

					BusinessLicenseAddressArr: [],
					BusinessLicenseProvinceId: null,
					BusinessLicenseCityId: null,
					BusinessLicenseAreaId: null,
					BusinessLicenseProvince: '',
					BusinessLicenseCity: '',
					BusinessLicenseArea: '',
					BusinessLicenseAddress: '',

					BusinessAddressArr: [],
					BusinessProvinceId: null,
					BusinessCityId: null,
					BusinessAreaId: null,
					BusinessProvince: '',
					BusinessCity: '',
					BusinessArea: '',
					BusinessAddress: '',

					LegalPersonBackIdCardPic: '',
					LegalPersonHoldIdCardPic: '',
					LegalPersonName: '',
					LegalPersonIdCardNo: '',
					LegalPersonIdCardStartTime: '',
					LegalPersonIdCardEndTime: '',
					starttime:'',
					longtime:false,
					BankId: null,
					BankAccountOpeningBranch: '',
					BankCardHolder: '',
					BankCardNo: '',
					BankCardImgUrl: '',
					OpeningBranchLicenseImgUrl: '',
					ShopHeadImgUrl:'',
					ShopInteriorImgUrl:'',
					
				},
				rules: {
					ConfidentialityAgreementImgUrlList: [{
						trigger: 'change',
						validator: checkImgUrlList
					}],
					ContactName: [{
						required: true,
						message: '请输入联系人姓名',
						trigger: 'blur',
					}],
					ContactPhone: [{
						required: true,
						trigger: 'blur',
						validator: checkContactPhone
					}],
					Email: [{
						required: true,
						trigger: 'blur',
						validator: checkEmail
					}],
					BusinessLicenseImgUrl: [{
						required: true,
						message: '请上传营业执照',
						trigger: 'blur',
					}],
					CompanyName: [{
						required: true,
						message: '请输入主体名称',
						trigger: 'blur',
					}],
					CompanyNameShort: [{
						required: true,
						message: '请输入商户简称',
						trigger: 'blur',
					}],
					BusinessLicenseRegistrationNumber: {
						required: true,
						message: '请输入营业执照注册号',
						trigger: 'blur',
					},
					BusinessLicenseAddressArr: [{
						required: true,
						message: '请选择营业执照省市区',
						trigger: 'change',
					}],
					BusinessLicenseAddress: [{
						required: true,
						message: '请输入营业执照地址',
						trigger: 'blur',
					}],
					BusinessAddressArr: [{
						required: true,
						message: '请选择营业地址省市区',
						trigger: 'change',
					}],
					BusinessAddress: [{
						required: true,
						message: '请输入营业地址',
						trigger: 'blur',
					}],

					LegalPersonHoldIdCardPic: [{
						required: true,
						message: '请上传身份证正面照',
						trigger: 'change',
					}],
					LegalPersonBackIdCardPic: [{
						required: true,
						message: '请上传身份证反面照',
						trigger: 'change',
					}],
					LegalPersonName: [{
						required: true,
						message: '请输入法人姓名',
						trigger: 'blur',
					}],
					LegalPersonIdCardNo: [{
						required: true,
						message: '请输入法人身份证号码',
						trigger: 'blur',
					}],
					LegalPersonIdCardStartTime: [{
						required: true,
						message: '请选择法人身份证有效期开始时间',
						trigger: 'change',
					}],
					starttime: [{
						required: true,
						message: '请选择法人身份证有效期开始时间',
						trigger: 'change',
					}],
					LegalPersonIdCardEndTime: [{
						required: true,
						message: '请选择法人身份证有效期结束时间',
						trigger: 'change',
					}],

					BankId: [{
						required: true,
						message: '请选择开户银行',
						trigger: 'change',
					}],
					BankAccountOpeningBranch: [{
						required: true,
						message: '请输入开户支行',
						trigger: 'blur',
					}],
					BankCardHolder: [{
						required: true,
						message: '请输入银行开户姓名',
						trigger: 'blur',
					}],
					BankCardNo: [{
						required: true,
						message: '请输入银行卡号',
						trigger: 'blur',
					}],
					BankCardImgUrl: [{
						required: true,
						message: '请上传银行卡照片',
						trigger: 'change',
					}],
					OpeningBranchLicenseImgUrl: [{
						required: true,
						message: '请上传开户行许可证',
						trigger: 'change',
					}],

				},

				arrDataList: [],
				arrProps: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				bankFilterList: [],
				editType: null,

				imgLoading1: false,
				imgLoading2: false,
				imgLoading3: false,
			}
		},
		created() {
			this.getArrDataList();
			this.getBankFilterList();
		},
		mounted() {
			this.getInit()
		},
		beforeDestroy() {
			window.localStorage.removeItem('canEditSetinfos')
		},
		methods: {
			
			getArrDataList() {
				var that = this;
				axios
					.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v='+new Date().getTime(), {})
					.then(function(response) {
						that.arrDataList = response.data;
					})
					.catch(function(error) {});
			},
			// 更新小程序
			async getInit() {
				try {
					this.loading = true
					let result = await freePayApplyInit()
					if (result.IsSuccess) {
						this.ApplyState = result.Result.ApplyState

						// 没有申请过支付（无id）或者免费版的申请状态===0，且不是只查看状态
						this.canEdit = (!result.Result.Id || result.Result.ApplyState != 0) && window.localStorage.getItem(
							'canEditSetinfos') != 'false'

						if (result.Result.Id) {
							this.ruleForm = result.Result

							this.ruleForm.BusinessLicenseAddressArr = [
								this.ruleForm.BusinessLicenseProvinceId + '',
								this.ruleForm.BusinessLicenseCityId + '',
								this.ruleForm.BusinessLicenseAreaId + ''
							]

							this.ruleForm.BusinessAddressArr = [
								this.ruleForm.BusinessProvinceId + '',
								this.ruleForm.BusinessCityId + '',
								this.ruleForm.BusinessAreaId + ''
							]
						}
					}
				} catch (e) {} finally {
					this.loading = false
					this.canEdit = true
				}
			},

			handleAddressChange1() {
				this.ruleForm.BusinessLicenseProvinceId = this.ruleForm.BusinessLicenseAddressArr[0];
				this.ruleForm.BusinessLicenseCityId = this.ruleForm.BusinessLicenseAddressArr[1];
				this.ruleForm.BusinessLicenseAreaId = this.ruleForm.BusinessLicenseAddressArr[2];
				this.arrDataList.map(item => {
					if (item.id == this.ruleForm.BusinessLicenseProvinceId) {
						this.ruleForm.BusinessLicenseProvince = item.name;
						item.child.map(items => {
							if (items.id == this.ruleForm.BusinessLicenseCityId) {
								this.ruleForm.BusinessLicenseCity = items.name;
								items.child.map(lastitems => {
									if (lastitems.id == this.ruleForm.BusinessLicenseAreaId) {
										this.ruleForm.BusinessLicenseArea = lastitems.name;
									}
								});
							}
						});
					}
				});
			},
			handleAddressChange2() {
				this.ruleForm.BusinessProvinceId = this.ruleForm.BusinessAddressArr[0];
				this.ruleForm.BusinessCityId = this.ruleForm.BusinessAddressArr[1];
				this.ruleForm.BusinessAreaId = this.ruleForm.BusinessAddressArr[2];
				this.arrDataList.map(item => {
					if (item.id == this.ruleForm.BusinessProvinceId) {
						this.ruleForm.BusinessProvince = item.name;
						item.child.map(items => {
							if (items.id == this.ruleForm.BusinessCityId) {
								this.ruleForm.BusinessCity = items.name;
								items.child.map(lastitems => {
									if (lastitems.id == this.ruleForm.BusinessAreaId) {
										this.ruleForm.BusinessArea = lastitems.name;
									}
								});
							}
						});
					}
				});
			},
			async getBankFilterList() {
				let result = await bankFilterList()
				this.bankFilterList = result.Result || [];
			},
			//门店头照
			handleSuccessShopHead(res){
				this.ruleForm.ShopHeadImgUrl = res[0]
				
				// console.log(this.ruleForm.ShopHeadImgUrl,'上传门店投站')
			},
			beforeUploadShopHead(file) {
				const isLt10M = file.size / 1024 / 1024 <= 10;
				if (!isLt10M) {
					this.$message({
						type: 'error',
						message: '上传图片大小不能超过10M',
						showClose: true
					})
					return false;
				}
			},
			//门店内景照
			handleSuccessShopInterior(res){
				this.ruleForm.ShopInteriorImgUrl = res[0]
			},
			beforeUploadShopInterior(file){
				const isLt10M = file.size / 1024 / 1024 <= 10;
				if (!isLt10M) {
					this.$message({
						type: 'error',
						message: '上传图片大小不能超过10M',
						showClose: true
					})
					return false;
				}
			},
			handleDelete(index) {
				this.ruleForm.ConfidentialityAgreementImgUrlList.splice(index, 1)
				// console.log(this.ruleForm.ConfidentialityAgreementImgUrlList.length)
			},
			handleSuccessPoster1(res) {
				
				// this.$message.success('上传成功')
				this.ruleForm.ConfidentialityAgreementImgUrlList.push(res[0])
				// console.log(this.ruleForm.ConfidentialityAgreementImgUrlList.length)
				//超出上传数量强行中断上传
				if(this.ruleForm.ConfidentialityAgreementImgUrlList.length>=5){
					this.$refs.uploader.abort()
				}
				// console.log(this.ruleForm.ConfidentialityAgreementImgUrlList.length,this.ruleForm.ConfidentialityAgreementImgUrlList)
				this.thedisabled = false
			},
			onprogress1(){
				// this.$message('请等待图片上传完成')
				this.thedisabled = true
			},
			onerror1(){
				// this.$message.error('上传失败')
				this.thedisabled = false
			},
			async handleSuccessPoster2(res) {
				this.imgLoading1 = true
				this.ruleForm.BusinessLicenseImgUrl = res[0]
				let result = await recognitionBusinessLicense({
					ImgUrl: this.imgUrl + res[0]
				})

				let provinceId = null
				let cityId = null
				let areaId = null
				this.arrDataList.forEach(item => {
					if (item.name == result.Province) {
						provinceId = item.id;
						item.child.forEach(items => {
							if (items.name == result.City) {
								cityId = items.id;
								items.child.forEach(lastitems => {
									if (lastitems.name == result.Area) {
										areaId = lastitems.id;
									}
								});
							}
						});
					}
				});
				this.ruleForm.CompanyName = result.CompanyName
				this.ruleForm.CompanyNameShort = result.CompanyNameShort
				this.ruleForm.BusinessLicenseRegistrationNumber = result.BusinessLicenseRegistrationNumber || result.EnterpriseId

				this.ruleForm.BusinessAddressArr = this.ruleForm.BusinessLicenseAddressArr = [provinceId + '', cityId + '', areaId +
					''
				]
				this.ruleForm.BusinessAddress = this.ruleForm.BusinessLicenseAddress = result.Address

				this.ruleForm.BusinessProvinceId = this.ruleForm.BusinessLicenseProvinceId = provinceId
				this.ruleForm.BusinessCityId = this.ruleForm.BusinessLicenseCityId = cityId
				this.ruleForm.BusinessAreaId = this.ruleForm.BusinessLicenseAreaId = areaId

				this.ruleForm.BusinessArea = this.ruleForm.BusinessLicenseArea = result.Area
				this.ruleForm.BusinessCity = this.ruleForm.BusinessLicenseCity = result.City
				this.ruleForm.BusinessProvince = this.ruleForm.BusinessLicenseProvince = result.Province

				this.imgLoading1 = false
			},
			async handleSuccessPoster3(res) {
				this.imgLoading2 = true
				this.ruleForm.LegalPersonHoldIdCardPic = res[0]
				let result = await recognitionIdCard({
					ImgUrl: this.imgUrl + res[0]
				})
				this.ruleForm.LegalPersonName = result.Name
				this.ruleForm.LegalPersonIdCardNo = result.IdNumber
				this.imgLoading2 = false
			},
			async handleSuccessPoster4(res) {
				this.imgLoading3 = true
				this.ruleForm.LegalPersonBackIdCardPic = res[0]
				let result = await recognitionIdCard({
					ImgUrl: this.imgUrl + res[0]
				})
				let dataArr = result.Validity.split("-");

				this.ruleForm.LegalPersonIdCardStartTime = dataArr[0]
				this.ruleForm.LegalPersonIdCardEndTime = dataArr[1]
				this.imgLoading3 = false
			},
			handleSuccessPoster5(res) {
				this.ruleForm.BankCardImgUrl = res[0]
			},
			handleSuccessPoster6(res) {
				this.ruleForm.OpeningBranchLicenseImgUrl = res[0]
			},
			beforeUploadPoster(file) {
				const isLt10M = file.size / 1024 / 1024 <= 10;
				// console.log(this.ruleForm.ConfidentialityAgreementImgUrlList)
				if (!isLt10M) {
					this.$message({
						type: 'error',
						message: '上传图片大小不能超过10M',
						showClose: true
					})
					return false;
				}
				// else if(this.ruleForm.ConfidentialityAgreementImgUrlList.length > 5){
				// 	this.$message({
				// 		type: 'error',
				// 		message: '上传图片数量不能超过5',
				// 		showClose: true
				// 	})
				// 	return false
				// }
			},
			beforeUploadPoster001(file){
					const isLt10M = file.size / 1024 / 1024 <= 10;
					console.log(this.ruleForm.ConfidentialityAgreementImgUrlList.length)
						if (!isLt10M) {
							this.$message({
								type: 'error',
								message: '上传图片大小不能超过10M',
								showClose: true
							})
							return false;
						}
						// else if(this.ruleForm.ConfidentialityAgreementImgUrlList.length > 5){
						// 	this.$message({
						// 		type: 'error',
						// 		message: '上传图片数量不能超过5',
						// 		showClose: true
						// 	})
						// 	return false
						// }
			},
			beforeremove(file,filelist){
				// console.log(file,filelist)
			},
			// aarr(arr){
			// 	console.log(arr)
			// },
			//限制上传数量
			handleExceed(files, fileList){
				// console.log(this.ruleForm.ConfidentialityAgreementImgUrlList.length,this.ruleForm.ConfidentialityAgreementImgUrlList)
				// console.log(files,fileList)
				this.$message.error(`上传图片超出数量`);
			},
			routerBack() {
				this.$router.back(-1)
			},
			saveForm(ruleForm) {
				this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
						try {
							this.loading = true
							// this.ruleForm.LegalPersonIdCardStartTime = this.ruleForm.longtime?this.ruleForm.starttime:this.ruleForm.LegalPersonIdCardStartTime
							this.ruleForm.LegalPersonIdCardEndTime = this.ruleForm.longtime?'2099-12-31':this.ruleForm.LegalPersonIdCardEndTime
							// console.log(this.ruleForm.LegalPersonIdCardStartTime,this.ruleForm.LegalPersonIdCardEndTime)
							let res = await freePayApply(this.ruleForm);
							if (res.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功'
								});
								this.loading = false
								this.$router.push('/setting/payApplyResult')
							}

						} catch (e) {
							this.loading = false
						} finally {
						}
					} else {
						this.$common.completeFormInformation('请完善申请资料', 'is-error')
						return false;
					}
				})
			}
		}
	}
</script>

<style lang='less' scoped>
	.account-item {
		.remark {
			color: #909399;
		}

		.upload-avatar {
			width: 145px;
			height: 145px;
			object-fit: contain;
		}
	}

	.footer {
		background-color: #fff;
		border-top: 1px solid #ebeef5;
		width: 100%;
		margin-right: 30px;
		height: 80px;
		text-align: center;
		line-height: 80px;
		position: fixed;
		bottom: 0px;
		z-index: 10;
	}
</style>
<style lang='less'>
	.img-scan-loading {
		width: 148px;
		height: 148px;
	}
</style>
